import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { EVENT_TYPE } from '../enums/event.enums';
import { GoogleTagManagerService, MESSAGE_TYPE, NotificationService, ProductConfig } from '@i-wyze/ui-shared-library';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { SessionStorageService } from './session.service';
import { ContentStore } from './content.store';
import { RouteService } from './route.service';
import { SessionStorageKey } from '../enums/session-storage-key.enum';
import { environment } from '../../../environments/environment';
import { Dictionary } from '../interfaces/dictionary.interace';
import { AppGtmEvent } from '../enums/gtm-event.enum';
export interface StartQuoteMetadata extends ProductConfig {
	fullName: string;
	cellphoneNumber: string;
	sales_platform_url: string;
}

@Injectable({
	providedIn: 'root'
})
export class EventService {
	router = inject(Router);
	http = inject(HttpClient);
	notificationService = inject(NotificationService);
	sessionService = inject(SessionStorageService);
	contentStore = inject(ContentStore);
	routeService = inject(RouteService);
	googleTagManager = inject(GoogleTagManagerService);

	wasLeadSubmissionSuccessful = signal<boolean>(false);
	isLoading = signal<boolean>(false);

	public handleEvent(event: any): void {
		switch (event.event) {
			case EVENT_TYPE.START_QUOTE:
				this.startQuote(event.metadata);
				break;
			case EVENT_TYPE.LEARN_MORE:
				this.handleNavigate(event.redirectUrl);
				break;
			case EVENT_TYPE.LOGIN:
				window.location.href = environment.portalUrl;
				break;
			case EVENT_TYPE.PRODUCT_LEARN_MORE:
				this.handleNavigate(event.metadata.learn_more_url);
				break;
			case EVENT_TYPE.CALL_BACK:
				this.submitLead(event.metadata);
				break;
			case EVENT_TYPE.FILTER_SECTIONS:
				this.filterSections(event.metadata);
				break;
			case EVENT_TYPE.DOWNLOAD:
				if (event.document) {
					this.handleDownload(event.document);
				}
				break;
			case EVENT_TYPE.VIEW:
				window.open(event.document, '_blank');
				break;
			case EVENT_TYPE.EMAIL:
				this.handleEmail(event.metadata.emailAddress);
				break;
			case EVENT_TYPE.DIAL:
				this.handleDial(event.metadata.contactNumber);
				break;
			case EVENT_TYPE.SCROLL:
				this.scrollToTop();
				break;
			case EVENT_TYPE.MAGICAL_MOMENTS_LEARN_MORE:
				// TODO: Find non hardcoded solution for attaching the url to the event (coming from button click on banner slider)
				//  and generify event
				this.router.navigate(['home/iwyze-magic-moments']).then(() => this.scrollToTop());
				break;
			default:
				console.error('Unknown event type', event);
		}
	}

	private scrollToTop() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	private handleDownload(document_url: string) {
		this.http.get(document_url, { responseType: 'blob' }).subscribe(blob => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'downloaded-file.pdf';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		});
	}

	private handleEmail(emailAddress: string) {
		const link = document.createElement('a');
		link.href = 'mailto:' + emailAddress;
		link.click();
	}

	private handleDial(contactNumber: string) {
		const link = document.createElement('a');
		link.href = 'tel:' + contactNumber;
		link.click();
	}

	private handleNavigate(url: string) {
		const link = document.createElement('a');
		link.href = url;
		link.click();
	}

	private filterSections(data: any): void {
		// TODO: Remove call to resetPagination, when Entries Paginator is able to sync itself with
		//  paginationOptions in contentStore, so that pagination can be preserved
		this.contentStore.resetPagination();
		this.contentStore.loadPage(this.routeService.getMappedRoute(), data.value);
	}

	private submitLead(data: any): void {
		this.isLoading.set(true);
		data.ucid = this.sessionService.getItem(SessionStorageKey.UCID);
		data.system = environment.systemId;
		data.company = environment.companyCode;
		data.firstName = data.fullName?.split(' ')[0] || '';
		data.surname = data.fullName?.split(' ')[data.fullName.split(' ').length - 1] || '';
		firstValueFrom(this.http.post<any>(`${environment.apiUrl}/leads`, data))
			.then(res => {
				if (res.status === 'failed') return this.handleLeadSubmissionFailure();
				this.handleLeadSubmissionSuccess(
					data.cellphoneNumber,
					data.product_code ?? data.product,
					data.product_line ?? data.productLine
				);
			})
			.catch(() => this.handleLeadSubmissionFailure());
	}

	private handleLeadSubmissionFailure() {
		this.isLoading.set(false);
		this.notificationService.openToastMessage({
			duration: 10000,
			type: MESSAGE_TYPE.Error,
			message: 'Failed to submit lead'
		});
	}
	private handleLeadSubmissionSuccess(cellphoneNumber: string, productCode: string, productLine?: string): void {
		this.isLoading.set(false);
		this.googleTagManager.pushEvent(AppGtmEvent.CALL_ME_BACK, {
			phoneNumber: cellphoneNumber,
			productCode: productCode,
			subProduct: productLine
		});

		this.wasLeadSubmissionSuccessful.set(true);
		this.notificationService.openToastMessage({
			duration: 10000,
			type: MESSAGE_TYPE.Success,
			message: 'Expect a call from us :)'
		});
	}

	private startQuote(metadata: StartQuoteMetadata): void {
		this.googleTagManager.pushEvent(AppGtmEvent.START_QUOTE, {
			productCode: metadata.product_code,
			subProduct: metadata.product_line,
			phoneNumber: metadata.cellphoneNumber
		});

		const prepopInfo = {
			name: metadata.fullName?.split(' ')[0] || '',
			surname: metadata.fullName?.split(' ')[metadata.fullName.split(' ').length - 1] || '',
			mobileNumber: metadata.cellphoneNumber
		};

		// TODO: add redirect url to products to support AoF navigation
		window.location.href = this.getProductSalesSiteUrl(
			metadata.sales_platform_url ?? environment.productRedirectUrl,
			metadata.product_code,
			metadata.product_line,
			prepopInfo
		);
	}

	private getProductSalesSiteUrl(
		baseUrl: string,
		productCode: string,
		productLineId: string,
		prepopInfo: Dictionary
	): string {
		const encodedPrepopInfo = encodeURIComponent(btoa(JSON.stringify(prepopInfo)));
		const referrer = this.sessionService.getItem(SessionStorageKey.ORIGIN);
		const ucid = this.sessionService.getItem(SessionStorageKey.UCID);

		let url = `${baseUrl}/?company=${environment.brand}&cpy=${environment.brand}`;
		url = `${url}&product=${productCode}&productLineId=${productLineId}&prepopInfo=${encodedPrepopInfo}&referrer=${referrer}&source=${ucid}&system=wyzeweb`;
		// TO DO This is not appending the sessionID to the URL
		url = this.googleTagManager.preserveCrossDomainTracking(url);
		return url;
	}
}
