import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICON, OptimizedImageComponent } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-customer-feedback-page',
	standalone: true,
	imports: [CommonModule, OptimizedImageComponent],
	templateUrl: './customer-feedback-page.component.html',
	styleUrls: ['./customer-feedback-page.component.scss']
})
export class CustomerFeedbackPageComponent {
	protected readonly ICON = ICON;

	@Input() data: any;
	@Input() index!: number;
}
