import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'wyzeweb-customer-feedback-slider',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './customer-feedback-slider.component.html'
})
export class CustomerFeedbackSliderComponent {
	@Input() data: any;
	@Input() index!: number;
}
