import { AfterViewInit, Component, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-post',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule, PipesModule],
	templateUrl: './post.component.html',
	styleUrl: './post.component.scss'
})
export class PostComponent implements AfterViewInit {
	@Input() data: any;
	width: any;
	height: any;
	elementRef = inject(ElementRef);
	renderer = inject(Renderer2);

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.handleResize();
		}, 500);
	}

	public get post() {
		return this.data.content.items[0];
	}

	public get hasSources() {
		return this.data.content.items[0].sources.length === 0;
	}

	handleResize(): void {
		const resizeElement = this.elementRef.nativeElement.querySelector('#screen-resize');
		if (!resizeElement) return;
		const dataSizes = resizeElement.getAttribute('data-sizes');

		if (dataSizes) {
			const sizesArray = dataSizes.split(',');
			if (window.innerWidth >= 768) {
				this.width = sizesArray[0]?.trim();
				this.height = sizesArray[1]?.trim();
			} else {
				if (sizesArray.length >= 4 && window.innerWidth < 768) {
					this.width = sizesArray[2]?.trim();
					this.height = sizesArray[3]?.trim();
				} else {
					this.width = sizesArray[0]?.trim();
					this.height = sizesArray[1]?.trim();
				}
			}
			this.renderer.setStyle(resizeElement, 'width', this.width ? `${this.width}px` : '100%');
			this.renderer.setStyle(resizeElement, 'height', this.height ? `${this.height}px` : 'auto');
		}
	}
}
