import { Injectable, computed, inject, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/ng/src/environments/environment';
import { BrandModel, ContentApiResponseModel, PageModel, SiteLinkModel } from '../models/nucleus.model';
import { RouteService } from './route.service';
import { SEOService } from './seo.service';

export type SectionPaginationOptions = {
	originalSectionIndex: number;
	pageNumber: number;
	pageLimit: number;
};

@Injectable({
	providedIn: 'root'
})
export class ContentStore {
	http = inject(HttpClient);
	routeService = inject(RouteService);
	seoService = inject(SEOService);

	siteLinks = signal<SiteLinkModel[]>([]);
	brand = signal<BrandModel>({
		long_company_name: '',
		short_company_name: '',
		disclaimer: '',
		top_banner_content: ''
	});
	page = signal<PageModel>({
		title: '',
		banner: {
			name: '',
			heading: '',
			sub_heading: '',
			mobile_background_image_url: '',
			desktop_background_image_url: '',
			has_lead_capture: true,
			has_call_me_back_button: true,
			has_start_quote_buton: true
		},
		sections: [],
		seo: {
			title: '',
			description: '',
			seo_image: '',
			keywords: '',
			schema: '',
			indexing: '',
			canonical_tag: ''
		},
		group_sections: false
	});
	productCategories = signal<any[]>([]);
	products = signal<any[]>([]);
	groupedSections = computed(() => {
		if (!this.page().group_sections) return null;
		const sections = this.page().sections;
		return sections.reduce((acc, section) => {
			const group = acc.find((acc_group: any) => acc_group.name === section.category);
			// @ts-ignore
			if (group) group.sections = [...group.sections, section];
			// @ts-ignore
			else acc.push({ name: section.category, variant: section.variant, sections: [section] });
			return acc;
		}, []);
	});
	currentFilter = signal<string>('');
	pageSearchResults = signal<any[]>([]);
	recentPosts = signal<any[]>([]);
	cards = signal<any[]>([]);
	isLoading = signal<boolean>(false);

	private paginatedPageSlug = '';
	private paginationOptions: SectionPaginationOptions[] = [];
	private originalPage: PageModel | null = null;

	constructor() {
		// TODO: consolidate these calls into a single operation
		this.loadAllSiteLinks();
		this.loadBrand();
	}

	loadAllSiteLinks() {
		firstValueFrom(this.http.get<SiteLinkModel[]>(`${environment.apiUrl}/content-management/site-links`))
			.then(links => this.siteLinks.set(links))
			.catch(error => this.handleError(error, 'Could not load links'));
	}

	loadBrand() {
		firstValueFrom(
			this.http.get<ContentApiResponseModel<BrandModel[]>>(
				`${environment.apiUrl}/content-management/brands/?company=${environment.brand}&platformCode=${environment.platformCode}`
			)
		)
			.then(response => this.brand.set(response.results[0]))
			.catch(error => this.handleError(error, 'Could not load brand'));
	}

	loadProductCategories() {
		firstValueFrom(
			this.http.get<ContentApiResponseModel<any[]>>(`${environment.apiUrl}/content-management/product-categories`)
		)
			.then(response => this.productCategories.set(response.results))
			.catch(error => this.handleError(error, 'Could not load product categories'));
	}

	getPagesBySearchTerm(searchTerm: string) {
		firstValueFrom(
			this.http.get<ContentApiResponseModel<any[]>>(
				`${environment.apiUrl}/content-management/search/?searchTerm=${searchTerm}`
			)
		)
			.then(response => {
				this.pageSearchResults.set(response.results);
			})
			.catch(error => this.handleError(error, 'Could not load product categories'));
	}
	getRecentPosts(searchTerm: string) {
		firstValueFrom(
			this.http.get<ContentApiResponseModel<any[]>>(
				`${environment.apiUrl}/content-management/recent-posts/?searchTerm=${searchTerm}`
			)
		)
			.then(response => this.recentPosts.set(response.results))
			.catch(error => this.handleError(error, 'Could not load product categories'));
	}
	getCards(searchTerm: string) {
		this.isLoading.set(true);
		firstValueFrom(
			this.http.get<ContentApiResponseModel<any[]>>(
				`${environment.apiUrl}/content-management/cards/?searchTerm=${searchTerm}`
			)
		)
			.then(response => {
				this.cards.set(response.results);
				this.isLoading.set(false);
			})
			.catch(error => this.handleError(error, 'Could not load product categories'));
	}
	loadProducts() {
		firstValueFrom(
			this.http.get<ContentApiResponseModel<any[]>>(`${environment.apiUrl}/content-management/products`)
		)
			.then(response => this.products.set(response.results))
			.catch(error => this.handleError(error, 'Could not load product categories'));
	}

	loadPage(slug: string | undefined | null, filter = this.currentFilter()) {
		if (!this.isValidSlug(slug)) return this.routeService.router.navigate(['']);
		this.isLoading.set(true);
		this.cards.set([]);

		if (this.paginatedPageSlug !== slug) this.resetPagination();
		const { pageLimit, pageNumber } = this.compilePagePaginationQueryParams();

		return firstValueFrom(
			this.http.get<PageModel>(
				`${environment.apiUrl}/content-management/page/?slug=${slug}&page=${pageNumber}&limit=${pageLimit}`
			)
		)
			.then(page => this.handlePageLoad(page, filter))
			.catch(error => {
				this.routeService.router.navigate(['']);
				this.handleError(error, 'Could not load page');
			});
	}

	// TODO: Move pagination to a separate service
	public paginateSection(sectionIndex: number, pageNumber: number, pageLimit: number): void {
		const currentPageSlug = this.routeService.getMappedRoute();
		this.paginatedPageSlug = currentPageSlug;

		const originalSectionIndex = this.page().sections[sectionIndex].originalIndex;

		const existingOptionIndex = this.paginationOptions.findIndex(
			x => x.originalSectionIndex === originalSectionIndex
		);
		if (existingOptionIndex === -1) {
			this.paginationOptions.push({ originalSectionIndex, pageNumber, pageLimit });
		} else {
			this.paginationOptions[existingOptionIndex].pageLimit = pageLimit;
			this.paginationOptions[existingOptionIndex].pageNumber = pageNumber;
		}

		this.loadPage(currentPageSlug);
	}

	public resetPagination(): void {
		this.paginationOptions = [{ originalSectionIndex: 0, pageNumber: 1, pageLimit: 1 }];
	}

	private compilePagePaginationQueryParams(): { pageLimit: string; pageNumber: string } {
		let pageLimit = '';
		let pageNumber = '';

		this.originalPage?.sections.forEach((section, index) => {
			const paginationOption = this.paginationOptions.find(x => x.originalSectionIndex === index);
			pageLimit += ',' + (paginationOption?.pageLimit || 10);
			pageNumber += ',' + (paginationOption?.pageNumber || 1);
		});

		return { pageLimit: pageLimit.slice(1), pageNumber: pageNumber.slice(1) };
	}

	handlePageLoad(page: PageModel, filter: string): void {
		this.originalPage = JSON.parse(JSON.stringify(page));
		this.currentFilter.set(filter);

		page.sections.forEach((section, index) => (section.originalIndex = index));

		if (this.currentFilter()) {
			page.sections = page.sections.filter(
				section => section.order === 0 || section.category === this.currentFilter()
			);
		}

		this.page.set(page);
		this.seoService.setSEOData(page.seo);
		this.isLoading.set(false);
	}

	filterLinksByGroup(group: string): SiteLinkModel[] {
		return this.siteLinks()
			.filter(link => link.group === group)
			.sort((a, b) => Number(a.order) - Number(b.order));
	}

	private handleError<T>(error: T, message: string) {
		console.error(message, error);
		this.isLoading.set(false);
	}

	isValidSlug(slug: string | undefined | null): boolean {
		if (!slug) return false;
		return !environment.disallowedSlugs.includes(slug);
	}
}
