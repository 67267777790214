import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSharedLibraryModule } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-post',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './post.component.html',
	styleUrl: './post.component.scss'
})
export class PostComponent {
	@Input() data: any;

	public get post() {
		return this.data.content.items[0];
	}

	public get hasSources() {
		return this.data.content.items[0].sources.length === 0;
	}
}
