import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'apps/ng/src/environments/environment';

declare const google: any;

@Injectable({
	providedIn: 'root'
})
export class ThirdPartyScriptService {
	platformId = inject(PLATFORM_ID);

	shouldLoadMap = signal<boolean>(false);

	private loadOnBrowser(path: string, name: string, cookieCategory: string) {
		// TODO: reimplement during hello peter integration
		setTimeout(() => this.shouldLoadMap.set(true), 5000);
		// if (isPlatformBrowser(this.platformId))
		// 	this.loadScript(path, name, cookieCategory)
		// 		.then(() => setTimeout(() => this.shouldLoadMap.set(true), 5000))
		// 		.catch(err => console.error(`Failed to load ${name} script:`, err));
	}

	private async loadScript(path: string, name: string, cookieCategory: string): Promise<void> {
		const response = await fetch(path);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const scriptText = await response.text();
		const scriptElement = document.createElement('script');
		scriptElement.textContent = scriptText;
		scriptElement.type = 'text/plain';
		scriptElement.setAttribute('data-cookiescript', 'accepted');
		scriptElement.setAttribute('data-cookiecategory', cookieCategory);
		scriptElement.async = true;
		scriptElement.defer = true;
		scriptElement.onerror = () => console.error(`Failed to load ${name} script`);
		document.head.appendChild(scriptElement);
		console.log(`${name} script loaded successfully`);
	}

	public loadFeefoScript(): void {
		this.loadOnBrowser('/feefo', 'Feefo', 'functionality');
	}

	public loadGoogleMapsScript() {
		this.loadOnBrowser('/google-maps-script', 'Google Maps', 'functionality');
	}

	public loadGoogleTagManagerScript() {
		this.loadOnBrowser('/google-tag-manager-script', 'Google Tag Manager', 'targeting');
	}
}
