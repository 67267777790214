import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'wyzeweb-customer-feedback-badge',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './customer-feedback-badge.component.html',
	styleUrl: './customer-feedback-badge.component.scss'
})
export class CustomerFeedbackBadgeComponent {}
