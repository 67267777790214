export const environment = {
	apiUrl: 'WW_NG_TOKEN_API_URL',
	serverUrl: 'https://prod-web.iwyze.co.za',
	systemId: 'WW_NG_TOKEN_SYSTEM_ID',
	brand: 'WW_NG_TOKEN_BRAND',
	companyCode: 'WW_NG_TOKEN_COMPANY_CODE',
	productRedirectUrl: 'WW_NG_TOKEN_PRODUCT_REDIRECT_URL',
	portalUrl: 'https://hub.iwyze.co.za/login',
	disallowedSlugs: 'favicon.ico',
	platformCode: 'SHARED'
};
