import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	PLATFORM_ID,
	Signal,
	computed,
	effect,
	inject,
	signal
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BUTTON_TYPE, DocumentCardComponent, ICON, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { MapDirectionsService } from '@angular/google-maps';
import { mapStyles } from './map-style.data';
import { LocationStoreService, SERVICE_TYPE } from '../../services/location.store';
import { GoogleAutocompleteComponent } from './google-autocomplete/google-autocomplete.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { EventService } from '../../services/event.service';
import { ThirdPartyScriptService } from '../../services/third-party-script.service';
import { Router } from '@angular/router';

declare const navigator: any;
declare const google: any;

class Size {
	constructor(public width: number, public height: number) {}

	equals(other: Size): boolean {
		return this.width === other.width && this.height === other.height;
	}
}

@Component({
	selector: 'wyzeweb-service-provider',
	standalone: true,
	imports: [
		CommonModule,
		UiSharedLibraryModule,
		GoogleMapComponent,
		GoogleAutocompleteComponent,
		DocumentCardComponent
	],
	providers: [MapDirectionsService],
	templateUrl: './service-provider.component.html',
	styleUrl: './service-provider.component.scss'
})
export class ServiceProviderComponent implements OnInit, AfterViewInit {
	public readonly ICON = ICON;
	public readonly SERVICE_TYPE = SERVICE_TYPE;
	@Input() data: any;

	platformId = inject(PLATFORM_ID);
	formBuilder = inject(FormBuilder);
	locationStore = inject(LocationStoreService);
	changeDetection = inject(ChangeDetectorRef);
	eventService = inject(EventService);
	thirdPartyScriptService = inject(ThirdPartyScriptService);
	router = inject(Router);
	placesService: any;

	constructor() {
		this.thirdPartyScriptService.loadGoogleMapsScript();
		// TODO: find a better way to do this, maybe split out individual logic
		effect(() => {
			if (
				!this.shouldDisplayLoader() &&
				!this.router.url.includes('vehicle') &&
				this.thirdPartyScriptService.shouldLoadMap()
			)
				return this.getFormControl('provider')?.setValue(SERVICE_TYPE.HIV);
			this.getFormControl('provider')?.setValue(SERVICE_TYPE.AUTOBOYS);
		});
	}

	providerForm!: FormGroup;

	options = [
		{ title: 'Vehicle Testing center', value: SERVICE_TYPE.AUTOBOYS },
		{ title: 'HIV Test location', value: SERVICE_TYPE.HIV }
	];

	mapOptions: google.maps.MapOptions = {
		center: { lat: 40, lng: -20 },
		zoom: 12,
		styles: mapStyles
	};

	vehicleMarkers = computed(() => {
		if (this.locationStore.vehicleTestingLocations().length === 0) return [];
		return this.locationStore
			.vehicleTestingLocations()
			.map(location => this.createMarker(location.latitude, location.longitude, ICON.ProviderIcon));
	});
	HIVMarkers = computed(() => {
		if (this.locationStore.HIVTestingLocations().length === 0) return [];
		return this.locationStore
			.HIVTestingLocations()
			.map(location => this.createMarker(location.latitude, location.longitude, ICON.ProviderIcon));
	});
	userMarker = signal<any>(null);
	destinationMarker = signal<any>(null);
	nearestMarkers = signal<any>(null);
	route = signal<any>(null);
	selectedProvider = computed(() => {
		if (!this.destinationMarker()) return null;
		return this.getProviderInfo(this.destinationMarker());
	});
	shouldDisplayLoader = computed(() => {
		return this.HIVMarkers().length < 1 || this.vehicleMarkers().length < 1;
	});

	ngOnInit(): void {
		this.setInitialPosition();
		this.locationStore.loadLocations();
		this.providerForm = this.formBuilder.group({
			provider: ['', Validators.required],
			address: ['', Validators.required]
		});
		this.providerForm.valueChanges.subscribe(() => {
			if (!this.thirdPartyScriptService.shouldLoadMap()) return;
			this.findNearestMarkers();
		});
	}

	ngAfterViewInit(): void {
		if (!isPlatformBrowser(this.platformId) || !this.thirdPartyScriptService.shouldLoadMap()) return;
		this.findNearestMarkers();
	}

	setInitialPosition() {
		if (isPlatformBrowser(this.platformId) && navigator && 'geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position: any) => {
				this.mapOptions.center = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				this.userMarker.set(
					this.createMarker(position.coords.latitude, position.coords.longitude, ICON.LocationIcon)
				);
			});
		}
	}

	createMarker(lat: number, lng: number, icon: string) {
		return {
			lat: lat,
			lng: lng,
			circleOptions: {
				fillColor: '#7b7b7b',
				fillOpacity: 0.5,
				strokeWeight: 1,
				strokeColor: '#7b7b7b',
				clickable: true,
				editable: false,
				zIndex: 1,
				radius: 10 // in meters
			},
			markerOptions: {
				lat: lat,
				lng: lng,
				clickable: true,
				icon: {
					url: icon,
					scaledSize: new Size(40, 40)
				}
			}
		};
	}

	public shouldDisplayMarkers(type: SERVICE_TYPE) {
		return type === this.providerForm?.get('provider')?.value;
	}

	public getFormControl(name: string) {
		return this.providerForm?.get(name) as FormControl;
	}

	private getDistanceComputedMarkers(markerSignal: Signal<any>, currentMarkerObj: any) {
		return markerSignal().map((marker: any) => {
			const distance = google.maps.geometry.spherical.computeDistanceBetween(
				new google.maps.LatLng(marker.lat, marker.lng),
				new google.maps.LatLng(this.userMarker().lat, this.userMarker().lng)
			);

			marker.distanceFromOrigin = distance;
			if (currentMarkerObj.nearest === null || distance < currentMarkerObj.nearest) {
				currentMarkerObj.nearest = distance;
				currentMarkerObj.closestMarker = marker;
			}
			return marker;
		});
	}

	private findNearestMarkers(): void {
		const markerObject = {
			closestMarker: null,
			nearest: null,
			markers: [{ distanceFromOrigin: 0 }]
		};
		if (SERVICE_TYPE.AUTOBOYS === this.getFormControl('provider')?.value)
			markerObject.markers = this.getDistanceComputedMarkers(this.vehicleMarkers, markerObject);

		if (SERVICE_TYPE.HIV === this.getFormControl('provider')?.value)
			markerObject.markers = this.getDistanceComputedMarkers(this.HIVMarkers, markerObject);
		markerObject.markers.sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin);
		this.nearestMarkers.set(markerObject.markers.slice(0, 6));
		const selectedMarker = markerObject.closestMarker;
		if (selectedMarker) this.destinationMarker.set(selectedMarker);
	}

	getProviderInfo(marker: any) {
		if (!marker) return;
		if (SERVICE_TYPE.AUTOBOYS === this.getFormControl('provider')?.value)
			return this.locationStore
				.vehicleTestingLocations()
				.find(
					(location: any) =>
						location.latitude === (marker.lat || marker.lat()) &&
						location.longitude === (marker.lng || marker.lng())
				);
		if (SERVICE_TYPE.HIV === this.getFormControl('provider')?.value)
			return this.locationStore
				.HIVTestingLocations()
				.find(
					(location: any) =>
						location.latitude === (marker.lat || marker.lat()) &&
						location.longitude === (marker.lng || marker.lng())
				);
	}

	get nearestProviders() {
		if (!this.nearestMarkers() || this.nearestMarkers().length < 0) return [];
		return this.nearestMarkers()?.map((marker: any) => {
			const info = this.getProviderInfo(marker);
			return {
				title: info?.name,
				variant: 'left-car',
				content: `
				${info?.contactNumber}
				</br>
				${info?.streetAddress}
				`,
				icon_name: 'LocationPinIcon',
				buttons: [
					{
						display_text: 'email',
						click_emission: 'email',
						button_type: BUTTON_TYPE.SECONDARY_SMALL
					},
					{
						display_text: 'dial',
						click_emission: 'dial',
						button_type: BUTTON_TYPE.SECONDARY_SMALL
					}
				],
				metadata: {
					contactNumber: info?.contactNumber,
					emailAddress: info?.emailAddress
				}
			};
		});
	}

	handleDestinationMarkerClick(event: any) {
		this.destinationMarker.set(event);
	}

	handleRouteUpdated(event: any) {
		this.route.set(event);
	}

	handleCardEvent(event: any, metadata: any) {
		this.eventService.handleEvent({ event: event.event, metadata: metadata });
	}

	handleUserMarkerUpdated(marker: any) {
		this.userMarker.set(this.createMarker(marker.lat, marker.lng, ICON.LocationIcon));
		this.findNearestMarkers();
	}
}
