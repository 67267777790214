import { DOCUMENT } from '@angular/common';
import { Injectable, RendererFactory2, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SEO } from '../models/nucleus.model';

@Injectable({
	providedIn: 'root'
})
export class SEOService {
	metaService = inject(Meta);
	titleService = inject(Title);
	rendererFactory = inject(RendererFactory2);
	document = inject(DOCUMENT);
	renderer = this.rendererFactory.createRenderer(null, null);

	public setSEOData(data: SEO) {
		this.titleService.setTitle(data.title);
		this.setMetaName('description', data.description);
		this.setMetaName('news_keywords', data.keywords);
		this.setMetaName('robots', data.indexing);
		this.renderer.appendChild(this.document.body, this.createSchemaScriptElement(data.schema));
	}

	private createSchemaScriptElement(schema: any): HTMLScriptElement {
		const script = this.renderer.createElement('script');
		script.type = 'application/ld+json';
		script.textContent = JSON.stringify(schema);
		return script;
	}

	private setMetaName(name: string, value: string) {
		this.metaService.updateTag({ name: name, content: value });
	}
}
